import {provideHttpClient, withFetch} from '@angular/common/http';
import {ApplicationConfig, provideZoneChangeDetection} from '@angular/core';
import {provideClientHydration} from '@angular/platform-browser';
import {provideAnimations} from '@angular/platform-browser/animations';
import {provideRouter} from '@angular/router';
import {provideEffects} from '@ngrx/effects';
import {provideStore} from '@ngrx/store';
import {provideStoreDevtools} from '@ngrx/store-devtools';
import {environment} from 'environments/environment';
import {routes} from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideZoneChangeDetection({eventCoalescing: true}),
    provideClientHydration(),
    provideAnimations(),
    provideStore(),
    provideHttpClient(withFetch()),
    provideStoreDevtools({
      name: 'Mercado-Meet',
      maxAge: 25,
      autoPause: true,
      trace: true,
      traceLimit: 75,
      logOnly: !environment.production,
      connectInZone: true
    }),
    provideEffects(),
  ],
};
